$(document).on('turbolinks:load', () => {

	$("#new_address, .edit_address").on("ajax:success", function(e, data, status, xhr){
		if(xhr.getResponseHeader('Location') != null) {
			$(this).find("input[type=submit]").prop("disabled", false);
			location.href = xhr.getResponseHeader('Location')
		}
  	}).on("ajax:error", function(e, data, status, xhr) {
  		 data = $.parseJSON(data.responseText);
  		$(this).render_form_errors('address', data);
	}).on("ajax:beforeSend", function(e, data, status, xhr){

	})

	$("#sign_up, #sign_in, #new_user, #edit_user").on("ajax:success", function(e, data, status, xhr){
		if(xhr.getResponseHeader('Location') != null) {
			$(this).find("input[type=submit]").prop("disabled", false);
			location.href = xhr.getResponseHeader('Location')
		}
  	}).on("ajax:error", function(e, data, status, xhr) {
  		 data = $.parseJSON(data.responseText);
  		$(this).render_form_errors('user', data);
	}).on("ajax:beforeSend", function(e, data, status, xhr){

	})

})
