$(document).on('turbolinks:load', () => {

  $("#new_pricing_plan").on("ajax:success", function(e, data, status, xhr){
		$(this).find("input[type=submit]").prop("disabled", false)
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
  	}).on("ajax:error", function(e, data, status, xhr) {
  		 data = $.parseJSON(data.responseText)
  		$(this).render_form_errors('pricing_plan', data)
	}).on("ajax:beforeSend", function(e, data, status, xhr){

	})

  var pricingListings = $('#pricing_plan_listings');
  var pricingItemPrice = $('#pricing_plan_item_price');
  var pricingMonths = $('#pricing_plan_months');
  var pricingTotal = $('#pricing_plan_total');
  var pricingPlanSubmit = $('#new_pricing_plan [type=submit]')
  var freeListings = $('#pricing_plan_items_free');


  function calculate(){
    var calcTotal;

    if(parseInt(pricingListings.val()) > freeListings.val()){
      calcTotal = (pricingListings.val() - freeListings.val()) * pricingItemPrice.val() * pricingMonths.val();
      pricingPlanSubmit.prop('disabled', false);
    } else {
      calcTotal = 0;
      pricingPlanSubmit.prop('disabled', true);
    }
    pricingTotal.val( parseFloat(calcTotal).toFixed(2) )
  }

  // calculate on init
  calculate()
  $('.spinner .btn.button-up, .spinner .btn.button-down').on('click', function() {
    var inputField = $(this).closest('.spinner').children('input')
    var monthsField = inputField.is("#pricing_plan_months");
    var minValue = monthsField ? 1 : freeListings.val();
    var multiply = $(this).hasClass('button-up') ? 1 : -1
    var inputInt = parseInt(inputField.val(), 10)
    if (inputInt > minValue){
      inputField.val( inputInt + (1 * multiply));
      inputField.focus()
      calculate()
    } else if(inputInt == minValue && multiply == 1) {
      inputField.val( inputInt + 1 );
      inputField.focus()
      calculate()
    } else {
      inputField.val( minValue );
      inputField.focus()
      calculate()
    }
  });

  // countdown start animation
  var animTimer = null;
  var lastVal = 0;
  var maxFreeVal = freeListings.val();

  if(animTimer) {
       clearTimeout(animTimer);
   }
   var updateProgress = function() {
     if(pricingListings.val() > maxFreeVal){
         pricingListings.val( pricingListings.val() - 1 );
         calculate();
         animTimer = setTimeout(updateProgress, (1 / pricingListings.val()) * 1000);
     }
   };
   updateProgress();

  $('#pricing_plan_listings, #pricing_plan_months').on('input', function(){
    if($(this).val() == "") $(this).val(1)
    calculate()
  })

  $('#new_pricing').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

})
