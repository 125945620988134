$(document).on('turbolinks:load', () => {

	$("#new_invitation, .edit_invitation").on("ajax:success", function(e, data, status, xhr){
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}

		if(data.hasOwnProperty("notice")){
			flashModal('Notice',data.notice);
		}
  	}).on("ajax:error", function(e, data, status, xhr) {
  		 data = $.parseJSON(data.responseText);
  		$(this).render_form_errors('invitation', data);
	}).on("ajax:beforeSend", function(e, data, status, xhr){

	})

	// check on start
	$('.survey-container label:first').addClass('checked');

	$('.survey-container input[type=radio]').on('change', function() {
		$('.survey-container label').removeClass('checked');
		$(this).closest('label').addClass('checked');
	});

});
