import ahoy from "ahoy.js"
ahoy.configure({cookies: false});

$(document).on('turbolinks:load', () => {
  var is_bot = navigator &&
    ((navigator.userAgent && /bot|crawl|spider|slurp|teoma/i.test(navigator.userAgent)) || navigator.webdriver);
  var cookieName = 'has_policy_consent';
  var isCookieSet = (value) => { 
    return document.cookie.indexOf(`${cookieName}=${value}`) > -1;
  };

  if(isCookieSet('1')){
    if (typeof hasCookieConsent === 'function') hasCookieConsent();
  }

  if(isCookieSet('0') || isCookieSet('1')){
    $('#policy-consent').hide();
  }

  if(is_bot) $('#policy-consent').hide(); // hide when is bot

  $('body')
    .on('click', '#accept-policy', function () {
      $('#policy-consent').hide();
      if (typeof hasCookieConsent === 'function') hasCookieConsent();
      document.cookie = `${cookieName}=1; path=/`;
      ahoy.track("cookie_accept", {locale: navigator.language});
    })
    .on('click', '#reject-policy', function () {
      $('#policy-consent').hide();
      document.cookie = `${cookieName}=0; path=/`;
      ahoy.track("cookie_reject", {locale: navigator.language});
    });
});
