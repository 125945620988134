$(document).on('turbolinks:load', () => {

	$('select.select-section').on("change", function(){
		location.href = $(this).val();
	})

	$("#new_shop").on("ajax:success", event => {
    const [data, status, xhr] = event.detail
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
  	}).on("ajax:error", function(e, data, status, xhr) {
  		 data = $.parseJSON(data.responseText);
  		$(this).render_form_errors('shop', data);
	}).on("ajax:beforeSend", function(e, data, status, xhr){

	})

	$(".edit_shop").on("ajax:success", event => {
    const [data, status, xhr] = event.detail
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
			return;
		}
		$(this).clear_form_errors();
		flashModal('Notice',data.notice);
		}).on("ajax:error", event => {
      const [data, status, xhr] = event.detail
      $(this).render_form_errors('shop', data.responseText);
	}).on("ajax:beforeSend", event => {

	})

})
