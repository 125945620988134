import ahoy from "ahoy.js"
ahoy.configure({cookies: false});

$(document).on('turbolinks:load', () => {

  var cartError = $('#cart_error')
  cartError.hide();

	var orderPrice = $("#order_price")
	var totalPrice = $("#total_price")
	var shippingPrice = $("#shipping_price")
	var shippingProcessing = $("#shipping_processing")

  //
  //	update carts
  //
  $('.edit-quantity, .edit-shipping')
  .on("ajax:before", (event) => {
    $(".loading-layer").addClass("active");
  })
  .on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
		$(this).find("input[type=submit]").prop("disabled", false);
		orderPrice.html(data.order_price);
		totalPrice.html(data.total_price);
		shippingPrice.html(data.shipping_price);
		shippingProcessing.html(data.shipping_processing);
    // if country code present do not display error border
    if(data.country_code === "") { 
      $(".edit-shipping").addClass("is-invalid");
    } else {
      $(".edit-shipping").removeClass("is-invalid");
    }
	}).on("ajax:error", (event) => {
    const [data, status, xhr] = event.detail
		cartError.show();
		cartError.html(data.responseText)
	}).on("ajax:complete", event => {
    $(".loading-layer").removeClass("active");
  })


  $("#new_address").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
	}).on("ajax:error", (event) => {
    const [data, status, xhr] = event.detail
		data = $.parseJSON(data.responseText);
		$(this).render_form_errors('contact_form', data);
	})

  $('#policies-modal').on('show.bs.modal', (event) => {
    ahoy.track("shipping_policies_modal", {locale: navigator.language});
  });

});
