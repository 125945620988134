$(document).on('turbolinks:load', () => {

	$('#new_site').on("ajax:success", function(e, data, status, xhr){
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
  	}).on("ajax:error", function(e, data, status, xhr) {
  		 data = $.parseJSON(data.responseText);
  		$(this).render_form_errors('site', data);
	}).on("ajax:beforeSend", function(e, data, status, xhr){

	})

})
