$(window).on('blur',() => { $('.dropdown-toggle').parent().removeClass('open'); } );

$(document).on('turbolinks:load', () => {

    // on scroll fade element
    $(window).scroll(function(){
      $(".fullscreen-video").css("opacity", 1 - $(window).scrollTop() / 600);
    });

    //
    // simple text slider
    //
    var jumbotronMain = $('.jumbotron.main');
    var jsteps = jumbotronMain.find('.jstep');
    var jstepsCount = jsteps.length;
    var currentJstep = 0;
    jumbotronMain.addClass('active')
    var jstepInterval = setInterval(function() {
      $(jsteps[currentJstep]).addClass('active')
      currentJstep++
      if(currentJstep > jstepsCount){
        currentJstep = 0;
        clearInterval(jstepInterval);
        $(jsteps[currentJstep]).parent().addClass('active')
      }
    }, 1500)

    // to etsy link confirmation
    $('.to-etsy-confirm').on('click', function() {
      $('#confirm-etsy-modal').modal('show');
      $('#confirm-etsy-button').attr('href', $(this).attr('href'));
      return false;
    })

    // preview tooltip
    $('.btn-preview').tooltip({title: 'This button is disabled in preview mode'})
    $('.btn-preview').on('click', function() {
      return false;
    })

    // popover for dashboard settings
    $('[data-toggle="popover"]').popover({
      trigger: 'focus',
      container: 'body',
      html: true
    })

    $('[data-toggle="tooltip"]').tooltip()

    $(".nano").nanoScroller();

    $.fn.clear_form_errors = function(){
      this.find('div').removeClass('has-error')
      this.find('span.error-message').remove()
      this.find('div.alert-danger').remove()
    }

    $.fn.clear_form_fields = function(){
      this.find(':input','#myform')
          .not(':button, :submit, :reset, :hidden')
          .val('')
          .removeAttr('checked')
          .removeAttr('selected')
    }

    $.fn.render_form_errors = function(model_name, errors) {
      form = this
      actionsTag = form.find('.actions')
      this.clear_form_errors()
      if(errors.hasOwnProperty("error") || errors.hasOwnProperty("base")){
        $('<div class="alert alert-danger" role="alert"/>').html(errors.error ? errors.error[0] : errors.base[0]).prependTo(actionsTag)
        // show all errors dont stop here
        // return false;
      }

      if(errors.hasOwnProperty("errors")){
        errors = errors.errors;
      }

      // error message for every field
      $.each(errors, function(field, messages){
        nested_field = field.split('.').map(function(name){ return '['+name+']' }).join('')
        input = form.find('input, select, textarea').filter(function(){
            name = $(this).attr('name')
            if(name){
                return name == (model_name + nested_field)
                //name.match(new RegExp(model_name + '\\[' + field + '\\(?'))
            }
        })
        input.closest('div').addClass('has-error')
        // to fix input-group
        if( $(input).parent('.input-group').length > 0 ) {
          input = input.parent()
        }
        // rails by default return errors in array
        if ($.isArray(messages)){
          input.parent().append('<span class="error-message">' + $.map(messages, function(m){ return m })[0] + '</span>')
        }
      })
    }

    $.scrollIt({
        topOffset: -96
    });
});
