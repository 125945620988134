import ahoy from "ahoy.js"
ahoy.configure({cookies: false});

$(document).on('turbolinks:load', () => {
  $("#new_contact_form").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
    $(event.target).find("input[type=submit]").prop("disabled", false);
    $('#contact-form-modal').modal('hide');

    $(event.target).find("textarea, input[type=text]").val(''); // clear inputs
    flashModal('Message sent','Thank you! Message has been sent. We will be in touch');
  }).on("ajax:error", (event) => {
    const [data, status, xhr] = event.detail
    renderFormErrors(event.target, 'contact_form', data);
  }).on("ajax:beforeSend", (event) => {

  })

  $('#contact-form-modal').on('show.bs.modal', (event) => {
	  var button = $(event.relatedTarget)
		var attach_id = button.data('attached-id')
	  var image = button.data('attached-img')
		var name = button.data('attached-name')
	  var modal = $(event.target)

    // TODO: uncomment when styling will be ready
		// if(attach_id && image && name){
		// 	$('#contact_form_attached_id').val(attach_id)
    //   var html = ''
		// 	html = '<div class="attach">'
		// 	html += '<div class="attach-img"><img src="'+image+'"/></div>'
		// 	html += '<div class="attach-title"><h5>'+name+'</h5></div>'
		// 	html += '</div>'
	  // 	modal.find('#modal-attached').html(html)
		// }
    ahoy.track("open_contact_form", {locale: navigator.language});
  })
})
