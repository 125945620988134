import Rails from "@rails/ujs"
Rails.start()
import Turbolinks from "turbolinks"
Turbolinks.start()
import "channels"

import "jquery"
import "bootstrap"
import "../stylesheets/sites/base"
import "@fortawesome/fontawesome-free/css/all.css";

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./enabled/', true, /\.js$/));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const videos = require.context('../videos', true)
// const video = require.context('../video', true)
// const imagePath = (name) => images(name, true)

// If a JS error occurs in the browser, the software can be left in 
// a bad state depending on how badly the JS console decides to crash.
// Subsequent errors can continue to be triggered because the page is
// never reloaded - so force a full page reload on the next page visit.

var jsErrorHasOccurred = false;

window.onerror = function () {
  jsErrorHasOccurred = true;
}

document.addEventListener("turbolinks:before-visit", function (event) {
  if (jsErrorHasOccurred == true) {
    var url = event.data.url;

    event.preventDefault(); // Cancel the turbolinks request
    window.location.href = url; // Do a regular page visit to clear the JS console
  }
});
