$(document).on('turbolinks:load', () => {
	$("#new_list_item").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
  }).on("ajax:error", (event) => {
      const [data, status, xhr] = event.detail
  		data = $.parseJSON(data.responseText)
  		$(this).render_form_errors('list_item', data)
  }).on("ajax:beforeSend", (event) => {

	})

  $("#new_elist_item").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
  }).on("ajax:error", (event) => {
      const [data, status, xhr] = event.detail
  		 data = $.parseJSON(data.responseText)
  		$(this).render_form_errors('elist_item', data)
  }).on("ajax:beforeSend", (event) => {

	})
  
  // Add minus icon for collapse element which is open by default
  $(".collapse.show").each(function () {
    $(this).prev(".collapse-header").find(".fa").addClass("fa-chevron-up").removeClass("fa-chevron-down");
  });

  // Toggle chevron-down chevron-up icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function () {
    $(this).prev(".collapse-header").find(".fa").removeClass("fa-chevron-down").addClass("fa-chevron-up");
  }).on('hide.bs.collapse', function () {
    $(this).prev(".collapse-header").find(".fa").removeClass("fa-chevron-up").addClass("fa-chevron-down");
  });

});
