flashModal = (type, message, label) => {
  type = type ? type : 'Message'
  var fm = $('#flash-modal');
  fm.find('.modal-title').html(type)
  fm.find('.modal-body').html(message)
  fm.modal('show');
}

confirmModal = (message,title) => {
  var fm = $('#confirm-modal');
  fm.find('.modal-title').html(title)
  fm.find('.modal-body').html(message)
  fm.modal('show');
}
